@font-face {
    font-family: "icons";
    src: url("./fonts/icons.eot?3ffa3b45e99d887c87fae00db38365f2#iefix") format("embedded-opentype"),
url("./fonts/icons.ttf?3ffa3b45e99d887c87fae00db38365f2") format("truetype"),
url("./fonts/icons.woff?3ffa3b45e99d887c87fae00db38365f2") format("woff"),
url("./fonts/icons.svg?3ffa3b45e99d887c87fae00db38365f2#icons") format("svg");
}

[class^="_icon_"], [class*=" _icon_"] {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

._icon_alert-circle:before {
    content: "\f101";
}
._icon_alert-info:before {
    content: "\f102";
}
._icon_alert-slim:before {
    content: "\f103";
}
._icon_align-center:before {
    content: "\f104";
}
._icon_align-left:before {
    content: "\f105";
}
._icon_align-right:before {
    content: "\f106";
}
._icon_ancillary:before {
    content: "\f107";
}
._icon_arrow-fit-left:before {
    content: "\f108";
}
._icon_arrow-fit-right:before {
    content: "\f109";
}
._icon_arrow-left:before {
    content: "\f10a";
}
._icon_arrow-right-filled:before {
    content: "\f10b";
}
._icon_arrow-right:before {
    content: "\f10c";
}
._icon_avatar-csm:before {
    content: "\f10d";
}
._icon_avatar:before {
    content: "\f10e";
}
._icon_bell:before {
    content: "\f10f";
}
._icon_benefits:before {
    content: "\f110";
}
._icon_bold:before {
    content: "\f111";
}
._icon_burger:before {
    content: "\f112";
}
._icon_calendar-checked:before {
    content: "\f113";
}
._icon_calendar:before {
    content: "\f114";
}
._icon_camera:before {
    content: "\f115";
}
._icon_cash:before {
    content: "\f116";
}
._icon_chart_up:before {
    content: "\f117";
}
._icon_check-circle:before {
    content: "\f118";
}
._icon_check:before {
    content: "\f119";
}
._icon_circle-minus-filled:before {
    content: "\f11a";
}
._icon_circle-minus:before {
    content: "\f11b";
}
._icon_circle-plus-full:before {
    content: "\f11c";
}
._icon_circle-plus:before {
    content: "\f11d";
}
._icon_clip:before {
    content: "\f11e";
}
._icon_close:before {
    content: "\f11f";
}
._icon_coin:before {
    content: "\f120";
}
._icon_communications:before {
    content: "\f121";
}
._icon_contactbook:before {
    content: "\f122";
}
._icon_contacts:before {
    content: "\f123";
}
._icon_dashboard:before {
    content: "\f124";
}
._icon_dollar-symbol:before {
    content: "\f125";
}
._icon_dollar:before {
    content: "\f126";
}
._icon_dots:before {
    content: "\f127";
}
._icon_download_cloud:before {
    content: "\f128";
}
._icon_download:before {
    content: "\f129";
}
._icon_edit-filled:before {
    content: "\f12a";
}
._icon_edit:before {
    content: "\f12b";
}
._icon_eligibility:before {
    content: "\f12c";
}
._icon_embed:before {
    content: "\f12d";
}
._icon_emergency:before {
    content: "\f12e";
}
._icon_excel_file:before {
    content: "\f12f";
}
._icon_external_link:before {
    content: "\f130";
}
._icon_eye-disabled:before {
    content: "\f131";
}
._icon_eye:before {
    content: "\f132";
}
._icon_font-size:before {
    content: "\f133";
}
._icon_generic_file:before {
    content: "\f134";
}
._icon_graphic:before {
    content: "\f135";
}
._icon_grey-circle:before {
    content: "\f136";
}
._icon_image_file:before {
    content: "\f137";
}
._icon_impl-checklist:before {
    content: "\f138";
}
._icon_info-circle:before {
    content: "\f139";
}
._icon_info:before {
    content: "\f13a";
}
._icon_italic:before {
    content: "\f13b";
}
._icon_link_chain:before {
    content: "\f13c";
}
._icon_link:before {
    content: "\f13d";
}
._icon_linkedin:before {
    content: "\f13e";
}
._icon_list_em:before {
    content: "\f13f";
}
._icon_list:before {
    content: "\f140";
}
._icon_lock-checked:before {
    content: "\f141";
}
._icon_lock:before {
    content: "\f142";
}
._icon_mail:before {
    content: "\f143";
}
._icon_medal:before {
    content: "\f144";
}
._icon_member_info:before {
    content: "\f145";
}
._icon_milestone:before {
    content: "\f146";
}
._icon_minus-round:before {
    content: "\f147";
}
._icon_money_couple:before {
    content: "\f148";
}
._icon_pdf_file:before {
    content: "\f149";
}
._icon_people:before {
    content: "\f14a";
}
._icon_percent-symbol:before {
    content: "\f14b";
}
._icon_person_new:before {
    content: "\f14c";
}
._icon_phone:before {
    content: "\f14d";
}
._icon_pipette:before {
    content: "\f14e";
}
._icon_play:before {
    content: "\f14f";
}
._icon_plus-round:before {
    content: "\f150";
}
._icon_power_new:before {
    content: "\f151";
}
._icon_power:before {
    content: "\f152";
}
._icon_presentation_file:before {
    content: "\f153";
}
._icon_preview:before {
    content: "\f154";
}
._icon_question-mark:before {
    content: "\f155";
}
._icon_remove:before {
    content: "\f156";
}
._icon_reporting:before {
    content: "\f157";
}
._icon_reset:before {
    content: "\f158";
}
._icon_settings_bold:before {
    content: "\f159";
}
._icon_settings:before {
    content: "\f15a";
}
._icon_sign-out:before {
    content: "\f15b";
}
._icon_sim_card:before {
    content: "\f15c";
}
._icon_sort:before {
    content: "\f15d";
}
._icon_sort1:before {
    content: "\f15e";
}
._icon_speaker:before {
    content: "\f15f";
}
._icon_stopwatch:before {
    content: "\f160";
}
._icon_strikethrough:before {
    content: "\f161";
}
._icon_summary:before {
    content: "\f162";
}
._icon_switch:before {
    content: "\f163";
}
._icon_tablet:before {
    content: "\f164";
}
._icon_text_file:before {
    content: "\f165";
}
._icon_thumbs-up:before {
    content: "\f166";
}
._icon_tooltip:before {
    content: "\f167";
}
._icon_transfer:before {
    content: "\f168";
}
._icon_trash:before {
    content: "\f169";
}
._icon_triangle-down:before {
    content: "\f16a";
}
._icon_triangle-down1:before {
    content: "\f16b";
}
._icon_triangle-top:before {
    content: "\f16c";
}
._icon_update:before {
    content: "\f16d";
}
._icon_upload:before {
    content: "\f16e";
}
._icon_user-check:before {
    content: "\f16f";
}
._icon_user:before {
    content: "\f170";
}
._icon_video_file:before {
    content: "\f171";
}
._icon_wallet:before {
    content: "\f172";
}
._icon_webcam:before {
    content: "\f173";
}
